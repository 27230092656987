export const photos8 = [
  {
    src: "imgs/z2.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z1.jpeg",
    width: 1,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z3.jpg",
    width: 4,
    height: 1,
    class: "kg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z5.jpg",
    width: 3,
    height: 2,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z6.jpeg",
    width: 3,
    height: 2,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z7.jpeg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z8.jpeg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z10.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z11.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z12.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z13.jpg",
    width: 4,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z14.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z15.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z16.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z17.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z18.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z19.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z20.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z21.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z22.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z23.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z24.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z25.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z26.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z27.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z28.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z29.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z31.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z32.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z33.jpg",
    width: 3,
    height: 2,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z35.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/z36.jpg",
    width: 6,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
];
