export const photos4 = [
  {
    src: "imgs/e1.jpeg",
    width: 2.4,
    height: 1,
    class : "lg:hover:scale-110 z-50 transition transform"
  },
  {
    src: "imgs/e2.jpeg",
    width: 2,
    height: 1,
    class : "lg:hover:scale-110 z-50 transition transform"
  }, 
  {
    src: "imgs/e3.jpeg",
    width: 2,
    height: 1,
    class : "lg:hover:scale-110 z-50 transition transform"
  }
  ];
  