export const photos9 = [
  {
    src: "imgs/k3.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k4.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k5.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k6.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k7.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k8.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k9.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k10.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k11.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k13.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k14.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k15.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k16.jpg",
    width: 4,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k17.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k18.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k19.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k20.jpg",
    width: 4,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k21.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k22.jpg",
    width: 4,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k23.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k24.jpg",
    width: 5,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k26.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k27.jpg",
    width: 5,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k28.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k29.jpg",
    width: 2,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
  {
    src: "imgs/k30.jpg",
    width: 3,
    height: 1,
    class: "lg:hover:scale-110 z-50 transition transform",
  },
];
