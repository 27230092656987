import React from "react";


export default function Carousel() {
  return (
    <div>
      <div className="hidden min-h-screen carp md:block">
        
      </div>

      <div className="md:hidden min-h-screen carm">
        
      </div>
    </div>
  );
}
