export const photos7 = [
    {
      src: "imgs/h1.jpeg",
      width: 2.5,
      height: 1,
      class : "lg:hover:scale-110 z-50 transition transform"
    },
    {
      src: "imgs/h2.jpeg",
      width: 2.5,
      height: 1,
      class : "lg:hover:scale-110 z-50 transition transform"
    }, 
    {
      src: "imgs/h3.jpeg",
      width: 2,
      height: 1,
      class : "kg:hover:scale-110 z-50 transition transform"
    },
    {
      src: "imgs/h4.jpeg",
      width: 2,
      height: 1,
      class : "lg:hover:scale-110 z-50 transition transform"
    },
    {
        src: "imgs/h5.jpeg",
        width: 16,
        height: 9,
        class : "lg:hover:scale-110 z-50 transition transform"
      }
    ];
    